import React, { useRef, useState } from "react";
import "./reset.css";

import {Link} from 'react-router-dom'
import whiteHook from "../../assets/images/Logo/white-hook.png";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextInput from "../../components/TextInput/TextInput.js";
import Helmet from "../../components/helmet";


import PropagateLoader from "react-spinners/PropagateLoader";

function Reset() {
  let { resetToken } = useParams();

  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  password.current = watch("password", "");

  const onSubmitRegister = (data) => {
    console.log(data);
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/reset`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
        password: data.password,
        token: resetToken,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg.msg);
      });
  };

  return (
    <>
    <Helmet title="drapem | Password Reset" />
    <div className="register-bg">
      <div className="large-hook">
        <img alt="drapem Hook" src={whiteHook} />
      </div>
      <div className="form-container">
        <div className="register-form-container">
          <div className="home-link-container">
            <Link className="home-link" to="/">
              Go Back
            </Link>
          </div>
          <h2 className="register-heading">Reset Password</h2>
          <form
            className="register-form"
            onSubmit={handleSubmit(onSubmitRegister)}
          >
            <TextInput
              type="email"
              name="email"
              label="Email"
              formRef={register({
                required: "The email field is required",
              })}
            />
            {errors.email && (
              <span className="span-error-register">
                {errors.lastname.message}
              </span>
            )}
            <TextInput
              type="password"
              name="password"
              label="New Password"
              formRef={register({
                required: "The password field is required",
                minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
                },
              })}
            />
            {errors.password && (
              <span className="span-error-register">
                {errors.password.message}
              </span>
            )}
            <TextInput
              type="password"
              name="confirmPassword"
              label="Confirm New Password"
              formRef={register({
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <span className="span-error-register">
                {errors.confirmPassword.message}
              </span>
            )}
            {message === null ? null : (
              <span className="message-reset">{message}</span>
            )}
            <button className="register-button" type="submit">
              {isLoading ? (
                <PropagateLoader
                  className="loader"
                  size={10}
                  color={"#70116b"}
                  loading={true}
                />
              ) : (
                <span>Reset Password</span>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
    </>
  );
}

export default Reset;
