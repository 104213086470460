import React, { useState, useEffect } from "react";
import "./dashboard.css";

import { useParams } from "react-router-dom";
import LogoWhite from "../../assets/images/Logo/drapem-logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faPercent,
  faTags,
  faAtom,
  faRuler,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import PacmanLoader from "react-spinners/PacmanLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSpring, animated } from "react-spring";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import Helmet from "../../components/helmet";

import TextInput from "../../components/TextInput/TextInput";

function Dashboard({ setIsAuth }) {
  let { userId } = useParams();
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    referrals: -1,
    userName: "",
    referralToken: "",
  });

  useEffect(() => {
    const token = window.sessionStorage.getItem("token");
    fetch(`${process.env.REACT_APP_SERVER}/profile/${userId}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        token: token,
        userId: userId,
      }),
    })
      .then((res) => res.json())
      .then((userData) => {
        setUserData({
          email: userData.email,
          firstName: userData.firstName,
          referrals: userData.referrals,
          userName: userData.userName,
          referralToken: userData.referralToken,
        });
      })
      .catch((err) => {
        alert("Something went wrong fetching user data");
      });
  }, [userId]);

  const handleSignOut = () => {
    window.sessionStorage.removeItem("token")
    setUserData({
      email: "",
      firstName: "",
      referrals: -1,
      userName: "",
      referralToken: "",
    });
    setIsAuth(false);
  };

  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmitUsername = (data) => {
    setIsLoading(true);
    const token = window.sessionStorage.getItem("token");
    fetch(`${process.env.REACT_APP_SERVER}/username`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: userData.email,
        username: data.username,
        token: token,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg.msg);
      })
      .catch((err) => {
        console.log(err.msg);
        // alert("Something went wrong reserving username");
      });
  };

  const [isCopied, setIsCopied] = useState(false);
  const copyAnimation = useSpring({
    to: isCopied
      ? async (next, cancel) => {
          await next({ transform: "translateY(0)" });
          await next({ transform: "translateY(-30px)" });
          await await setIsCopied(false);
          cancel();
        }
      : null,
    from: { transform: "translateY(-30px)" },
    config: { tension: 250, friction: 40 },
  });

  return (
    <>
      <Helmet title="drapem | Referral Dashboard" />
      <div className="dashboard-container">
        <div className="dashboard">
          <div className="dashboard-heading-container">
            <div className="dashboard-heading">
              <div className="dashboard-logo">
                <img alt="drapem logo" src={LogoWhite} />
              </div>
              <div className="dashboard-user-account">
                <span onClick={handleSignOut}>Sign Out</span>
              </div>
            </div>
          </div>
          <h1 className="dashboard-user-greeting">
            Hi, {userData.firstName}! Welcome to your referral dashboard.
          </h1>
          <div className="dashboard-tier-link">
            <div className="dashboard-tier-progression">
              <h2 className="dashboard-progress">Progress</h2>
              <div className="dashboard-tiers">
                <div className="dashboard-tier-container 0">
                  <div className="dashboard-tier icon 0">
                    {userData.referrals >= 0 ? (
                      <FontAwesomeIcon icon={faBullhorn} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faBullhorn} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 0">
                    Be the First to Hear When We Launch!
                  </h2>
                  <h3 className="dashboard-tier requirement 0">Sign Up</h3>
                </div>

                <div className="dashboard-tier-container 1">
                  <div className="dashboard-tier username 1">
                    {userData.referrals >= 2 ? (
                      <h2 style={{ color: "#cf6679" }}>@username</h2>
                    ) : (
                      <h2>@username</h2>
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 1">
                    Reserve Your Username
                  </h2>
                  <h3 className="dashboard-tier requirement 1">2 invites</h3>
                </div>

                <div className="dashboard-tier-container 2">
                  <div className="dashboard-tier icon 2">
                    {userData.referrals >= 4 ? (
                      <FontAwesomeIcon icon={faAtom} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faAtom} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 2">
                    Exlusive Beta Access
                  </h2>
                  <h3 className="dashboard-tier requirement 2">3 invites</h3>
                </div>

                <div className="dashboard-tier-container 3">
                  <div className="dashboard-tier icon 3">
                    {userData.referrals >= 10 ? (
                      <FontAwesomeIcon icon={faRuler} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faRuler} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 3">
                    Custom drapem Measuring Tape
                  </h2>
                  <h3 className="dashboard-tier requirement 3">10 invites</h3>
                </div>

                <div className="dashboard-tier-container 4">
                  <div className="dashboard-tier icon 4">
                    {userData.referrals >= 25 ? (
                      <FontAwesomeIcon icon={faPercent} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faPercent} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 4">
                    20% Discount Voucher
                  </h2>
                  <h3 className="dashboard-tier requirement 4">20 invites</h3>
                </div>

                <div className="dashboard-tier-container 5">
                  <div className="dashboard-tier icon 5">
                    {userData.referrals >= 50 ? (
                      <FontAwesomeIcon icon={faTags} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faTags} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 5">
                    50% Discount Voucher
                  </h2>
                  <h3 className="dashboard-tier requirement 5">50 invites</h3>
                </div>

                <div className="dashboard-tier-container 6">
                  <div className="dashboard-tier icon 6">
                    {userData.referrals >= 100 ? (
                      <FontAwesomeIcon icon={faGift} color="#cf6679" />
                    ) : (
                      <FontAwesomeIcon icon={faGift} />
                    )}
                  </div>
                  <h2 className="dashboard-tier heading 6">
                    Free Item of Your Choice
                  </h2>
                  <h3 className="dashboard-tier requirement 6">100 invites</h3>
                </div>
              </div>
            </div>
            <div className="dashboard-user-link">
              <h3 className="dashboard-link-header">
                Invite with this link to level up your tiers!
              </h3>

              <CopyToClipboard
                text={`https://drapem.com/invite/${userData.referralToken}`}
                onCopy={() => setIsCopied(true)}
              >
                <div className="user-shareable-link container">
                  <animated.div
                    style={copyAnimation}
                    className="dashboard-copy-alert"
                  >
                    <span className="clipboard-message">
                      Copied to clipboard
                    </span>
                  </animated.div>
                  <h2 className="user-shareable-link">{`https://drapem.com/invite/${userData.referralToken}`}</h2>
                </div>
              </CopyToClipboard>

              <div className="social-share-container">
                <FacebookShareButton
                  url={`https://drapem.com/invite/${userData.referralToken}`}
                  quote="drapem - Bringing Fashion Online The Right Way"
                  hashtag="#drapemFashion"
                >
                  <FacebookIcon size={40} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`https://drapem.com/invite/${userData.referralToken}`}
                  quote="drapem - Bringing Fashion Online The Right Way"
                  hashtag="#drapemFashion"
                >
                  <TwitterIcon size={40} />
                </TwitterShareButton>
                <EmailShareButton
                  url={`https://drapem.com/invite/${userData.referralToken}`}
                  subject="Invite to drapem - Bringing Fashion Online The Right Way"
                  body="Hi! Check out drapem, a fashion marketplace that seamlessly connects the clothing process from the designer to the shopper."
                  separator=" - "
                >
                  <EmailIcon size={40} />
                </EmailShareButton>
              </div>

              <div className="dashboard-count-container">
                <h3 className="dashboard-count-message">
                  Your referral count:
                </h3>
                <h1 className="dasboard-referral-count">
                  {userData.referrals}
                </h1>
              </div>
              <div className="dashboard-username-container">
                {userData.referrals >= 2 ? null : (
                  <div className="disabled-overlay" />
                )}
                <span className="dashboard-username-header">
                  Reach 2 referrals and reserve your username!
                </span>
                <form
                  className="dashboard-update-username"
                  onSubmit={handleSubmit(onSubmitUsername)}
                >
                  <TextInput
                    type="text"
                    name="username"
                    label="Username"
                    formRef={register({
                      required: "The first name field is required",
                      pattern: {
                        value: /^[A-Za-z0-9]+(?:[._][A-Za-z0-9]+)*$/,
                        message: "Please enter a valid username",
                      },
                    })}
                  />
                  {errors.firstname && (
                    <span className="span-error-username">
                      {errors.username.message}
                    </span>
                  )}
                  <button className="username-button" type="submit">
                    {isLoading ? (
                      <PacmanLoader
                        className="loader"
                        size={10}
                        color={"#efee9d"}
                        loading={true}
                      />
                    ) : userData.referrals >= 2 ? (
                      <span>Reserve</span>
                    ) : (
                      <span>{2 - userData.referrals} to go!</span>
                    )}
                  </button>
                </form>
                {message !== null ? (
                  <span className="Server-Message">{message}</span>
                ) : null}
              </div>
              <div className="dashboard-leaderbord-container"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
