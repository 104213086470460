import React, { useState } from "react";

import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-scroll";

import "./hero.css";
import heroImg from "../../assets/images/Hero/hero-img.png";
import TextInput from "../TextInput/TextInput.js";
import OnSignUpPopUp from "../OnSignUpPopUp/OnSignUpPopUp.js";
import PropagateLoader from "react-spinners/PropagateLoader";

function Hero() {
  const { register, handleSubmit, control, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmitSignUp = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/signup`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
        usertype: data.usertype.value,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg);
        togglePopup();
      });
  };

  // console.log(watch("usertype"));
  // console.log(watch("email"));

  const [PopUp, setPopUp] = useState(false);

  const togglePopup = () => {
    setPopUp(!PopUp);
  };

  // const [userType, setUserType] = useState({
  //   value: "",
  //   label: "Select",
  // });

  // const handleSelected = (userType) => {
  //   setUserType(userType);
  // };

  const userTypeOptions = [
    { value: "Designer", label: "Designer" },
    { value: "Shopper", label: "Shopper" },
  ];

  //<SELECT /> Styling
  const selectStyles = {
    option: (base, { isFocused }) => ({
      ...base,
      borderRadius: "20px",
      color: isFocused ? "white" : "#692aa9",
      backgroundColor: isFocused ? "#8142c0" : "white",
    }),
    control: (base) => ({
      ...base,
      // width: `${8 * userType.label.length + 70}px`,
      width: `${134}px`,
      borderWidth: "2px",
      borderColor: "#8142c0",
      height: "35px",
      minHeight: "35px",
      flexWrap: "nowrap !important",
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: "600",
      fontSize: "16",
      color: "#8142c0",
    }),
  };

  //<SELECT /> Theming
  const selectTheme = (theme) => {
    return {
      ...theme,
      height: "30px",
      minHeight: "30px",
      borderRadius: "20px",
      colors: {
        ...theme.colors,
        primary: "#8142c0",
      },
    };
  };

  return (
    <header className="hero">
      <div className="herotextcta">
        <div className="purpdash"></div>
        <h1 className="heromaintext">The Next Generation of Fashion</h1>
        <h2 className="herosuptext">
          Relieve all the hassles of online shopping through a made-to-order
          marketplace that enables designers and empowers shoppers.
        </h2>
        <Link
          activeClass="active"
          to="prelaunch"
          spy={true}
          smooth={true}
          offset={-250}
          duration={500}
        >
          <p className="referral">
            Join our invite program and win some pre-launch exclusives!
          </p>
        </Link>
        <form className="cta" onSubmit={handleSubmit(onSubmitSignUp)}>
          <div className="useremail">
            <div className="usertype">
              <Controller
                name="usertype"
                control={control}
                defaultValue=""
                rules={{
                  required: { value: true, message: "This field is required" },
                }}
                render={({ name, onChange, value }) => (
                  <Select
                    name={name}
                    theme={selectTheme}
                    styles={selectStyles}
                    value={value}
                    onChange={onChange}
                    options={userTypeOptions}
                  />
                )}
              />
              {errors.usertype && (
                <span style={{ fontSize: "0.9rem", color: "red" }}>
                  Select an option
                </span>
              )}
            </div>
            <div className="emailinput">
              <TextInput
                type="email"
                name="email"
                label="Email"
                formRef={register({ required: true })}
              />
              {errors.email && (
                <span style={{ fontSize: "0.9rem", color: "red" }}>
                  The email field is required
                </span>
              )}
            </div>
          </div>
          <button type="submit" className="signup">
            {isLoading ? (
              <PropagateLoader
                className="loader"
                size={10}
                color={"#70116b"}
                loading={true}
                margin={0}
                padding={0}
              />
            ) : (
              <span>Sign Up</span>
            )}
          </button>
        </form>
        {PopUp && <OnSignUpPopUp handleClose={togglePopup} msg={message} />}
      </div>
      <div className="heroimage">
        <img
          className="heroimage"
          src={heroImg}
          alt="Fashion Manufacturing Process"
        />
      </div>
    </header>
  );
}

export default Hero;
