import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Analytics from "react-router-ga";
// import ReactGA from "react-ga";

import Home from "./Pages/Home/Home";
import Register from "./Pages/Register/register";
import Dashboard from "./Pages/Dashboard/dashboard";
import Reset from "./Pages/Reset/reset";
import NotFound from "./Pages/404/404";
import Helmet from "./components/helmet";

function App() {
  // Google Analytics
  // useEffect(() => {
  //   ReactGA.initialize(process.env.REACT_APP_GA_ID);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  const [isAuth, setIsAuth] = useState(false);
  const [userId, setUserId] = useState(null);

  function PrivateRoute({ component: Component, isAuth, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth === true ? (
            <Component isAuth={isAuth} setIsAuth={setIsAuth} {...props} />
          ) : (
            // <Component isAuth={isAuth} setIsAuth={setIsAuth} {...props} />
            <Redirect to="/" />
          )
        }
      />
    );
  }

  return (
    <>
      <Helmet />
      <Router>
        <Analytics id={process.env.REACT_APP_GA_ID}>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Home setIsAuth={setIsAuth} setUserId={setUserId} {...props} />
              )}
            />
            <Route
              path="/invite/:Token"
              render={(props) => (
                <Home setIsAuth={setIsAuth} setUserId={setUserId} {...props} />
              )}
            />
            <Route path="/register/:signUpToken">
              <Register />
            </Route>
            <Route path="/reset/:resetToken">
              <Reset />
            </Route>
            <PrivateRoute
              isAuth={isAuth}
              path="/profile/:userId"
              component={Dashboard}
            />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Analytics>
        {isAuth ? <Redirect to={`/profile/${userId}`} /> : null}
      </Router>
    </>
  );
}

export default App;
