import React, { useState } from "react";

import "./burger.css";
import Menu from "../Menu/Menu.js";

const Burger = ({ scrolled, setIsAuth, setUserId}) => {
  //Burger Animation Hook
  const [open, setOpen] = useState(false);

  const onBurgerClick = () => {
    setOpen(!open);
  };

  //Change burger color when burger is clicked
  let burgerClasses = ["burger"];
  if (open) {
    burgerClasses.push("burgeropen");
  }

  let scrolledClasses=[""]
  if(scrolled) {
    scrolledClasses.push("scrolledBurger")
  }

  return (
    <div className="burgerdiv">
      <div className="styledBurger" onClick={onBurgerClick}>
        <div className={`${burgerClasses.join(" ")} ${scrolledClasses.join(" ")}`} />
        <div className={`${burgerClasses.join(" ")} ${scrolledClasses.join(" ")}`} />
        <div className={`${burgerClasses.join(" ")} ${scrolledClasses.join(" ")}`} />
      </div>
      <Menu scrolled={scrolled} open={open} setOpen={setOpen} setIsAuth={setIsAuth} setUserId={setUserId}/>
    </div>
  );
};

export default Burger;
