import React from "react";
import "./404.css";

import { Link } from "react-router-dom";
import Helmet from "../../components/helmet";

function NotFound() {
  return (
    <>
          <Helmet title="drapem | Page not found" />
    <div className="notfound">
      <div className="notfound-contents">
        <h1 className="notfound-header">404 - Page not found</h1>
        <Link to="/" className="notfound-link">
          Go Back
        </Link>
      </div>
    </div>
    </>
  );
}

export default NotFound;
