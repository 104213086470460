import React from "react";
import "./shop.css";

import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import webshop from "../../assets/images/Shop/webshop.png";
import apiimg from "../../assets/images/Shop/api-img.png";
import tapeMeasure from "../../assets/images/Shop/tapemeasure.jpg";
import tailor from "../../assets/images/Shop/tailor.jpg";
import sustainable from "../../assets/images/Shop/sustainable.png";

function Shop() {
  return (
    <div className="shopsegment">
      <div className="seg">
        <div className="segline" />
        <h3 className="segtitle">Shop</h3>
      </div>
      <div className="style">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <div className="shopimage" style={{ opacity }}>
                  <img className="shopimage" src={webshop} alt="mock webshop" />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-50%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="styletext" style={{ transform, opacity }}>
                  <h2 className="styleheading">Realize your style</h2>
                  <p className="styledesc">
                    Shop through a variety of selections created by designers.
                    Providing all your fashion needs in one place!
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>

      {/* Measured to you segment */}

      <div className="tailoredfit">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="apiimage" style={{ transform, opacity }}>
                  <img src={apiimg} alt="api" />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="apitextone" style={{ transform, opacity }}>
                  <h2 className="sizehead apiheader">Know Your Size</h2>
                  <p className="sizedesc api">
                    Measure yourself by uploading/taking a picture through an
                    integrated API
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <div className="tapemeasurefashion">
          <div className="tpmbckgrd" />

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tapemeasure" style={{ transform, opacity }}>
                    <img src={tapeMeasure} alt="Tape Measure" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <p className="oldfashion" style={{ transform, opacity }}>
                    Or just do things the old fashioned way
                  </p>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ opacity }) => (
                <div className="horizontalpurpdash" style={{ opacity }} />
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "rotateY(0)" : "rotateY(-180deg)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="tailorpic" style={{ transform, opacity }}>
                  <img src={tailor} alt="Tailor" />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(5%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="tailortxtone" style={{ transform, opacity }}>
                  <h2 className="sizehead tailored">Tailored To You</h2>
                  <p className="sizedesc tailored">
                    Your chosen manufacturer will curate the design to your
                    measurements
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <div className="tailoredcatchp">
          <div className="catchpbkg" />

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatex(0)" : "translatex(5%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <p
                    className="tailoredcatchptxt"
                    style={{ transform, opacity }}
                  >
                    Never worry about size again!
                  </p>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
      </div>

      <div className="sustainable-fashion">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <img
                  className="sustainable-image"
                  style={{ transform, opacity }}
                  src={sustainable}
                  alt="Sustainable Fashion"
                />
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div
                  className="sustainable-fashion-text-container"
                  style={{ transform, opacity }}
                >
                  <h2 className="sustainable-fashion-heading">
                    Make Fashion Sustainable
                  </h2>
                  <p className="sustainable-fashion-text">
                    Fashion accounts for 35% of microplastic flows into the
                    ocean. By purchasing made-to-order fashion you are reducing
                    the waste generated by the mass manufacturing of fast
                    fashion.
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default Shop;
