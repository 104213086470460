import React from "react";
import { Helmet } from "react-helmet";
import drapemPic from "../assets/images/Logo/drapem-purp-background.png";

export default function HelmetMetaData(props) {
  let currentUrl = "https://www.drapem.com";
  let quote = props.quote !== undefined ? props.quote : "";
  let title =
    props.title !== undefined
      ? props.title
      : "drapem | The Next Generation of Fashion";
  let image = props.image !== undefined ? props.image : { drapemPic };
  let description =
    props.description !== undefined
      ? props.description
      : "drapem is a fashion marketplace to shop clothes designed by users tailored to you. The next generation of fashion through made-to-order clothing that enables designers and empowers shoppers. " +
        "We aim to provide you the best experience whether you're a fashion designer or shopper";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#drapemFashion";
  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content="%PUBLIC_URL%/apple-touch-icon.png"
      />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta
        property="og:image"
        content="https://www.drapem.com/drapem-purp-background.png"
      />
      <meta
        property="og:image:secure_url"
        content="https://www.drapem.com/drapem-purp-background.png"
      />
      <meta
        property="og:image:alt"
        content="drapem logo"
      />
      <meta content="image/png" property="og:image:type" />
      <meta property="og:site_name" content="drapem" />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
