import React, { useState } from "react";
import "./authPopUp.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle as UserCircle } from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../TextInput/TextInput.js";
import { useSpring, animated } from "react-spring";
import Select from "react-select";
import PropagateLoader from "react-spinners/PropagateLoader";
import OnSignUpPopUp from "../OnSignUpPopUp/OnSignUpPopUp.js";

function AuthPopUp({ handleClose, setIsAuth, setUserId }) {
  const { register, handleSubmit, errors, control } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    errors: errors3,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [authMessage, setAuthMessage] = useState(null);
  const [forgotMessage, setForgotMessage] = useState(null);

  const storeToken = (token) => {
    window.sessionStorage.setItem("token", token)
  }

  const onSubmitSignIn = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/signin`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        if (msg.status === "Success") {
          if (msg.username === null) {
            setUserId(msg.referralToken);
          } else {
            setUserId(msg.username);
          }
          storeToken(msg.token)
          setIsAuth(true);
        } else {
          setAuthMessage(msg.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setAuthMessage("There was a problem signing in");
      });
  };

  const onSubmitSignUp = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/signup`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
        usertype: data.usertype.value,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg);
        togglePopup();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setAuthMessage("There was a problem signing up");
      });
  };

  const onSubmitForgotPassword = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/forgot`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setForgotMessage(msg.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setForgotMessage("An error occured.");
      });
  };

  const [PopUp, setPopUp] = useState(false);

  const togglePopup = () => {
    setPopUp(!PopUp);
  };

  const [signUpForm, setSignUpForm] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signInForm, setSignInForm] = useState(true);

  const signUpFormAnime = useSpring({
    tranform: signUpForm ? `rotateX(0deg)` : `rotateX(180deg)`,
    opacity: signUpForm ? 1 : 0,
    zIndex: signUpForm ? 10 : -1,
    config: { duration: 0.1},
  });

  const signInFormAnime = useSpring({
    tranform: signInForm ? `rotateX(0deg)` : `rotateX(180deg)`,
    opacity: signInForm ? 1 : 0,
    zIndex: signInForm ? 10 : -1,
    config: { duration: 0.1},
  });

  const forgotPasswordAnime = useSpring({
    tranform: forgotPassword ? `rotateX(0deg)` : `rotateX(180deg)`,
    opacity: forgotPassword ? 1 : 0,
    zIndex: forgotPassword ? 10 : -1,
    config: { duration: 0.1},
  });

  // const signUpFormAnime = useSpring({
  //   tranform: signUpForm
  //   ? `perspective(600px) rotateX(0)`
  //   : forgotPassword
  //   ? `perspective(600px) rotateY(0deg)`
  //   : `perspective(600px) rotateY(180deg)`,
  //   opacity: signUpForm ? 1 : 0,
  //   zIndex: signUpForm ? 10 : -1,
  //   config: { mass: 5, tension: 500, friction: 80 },
  // });

  // const signInFormAnime = useSpring({
  //   tranform: signInForm
  //   ? `perspective(600px) rotateX(0)`
  //   : forgotPassword
  //   ? `perspective(600px) rotateX(180deg)`
  //   : `perspective(600px) rotateY(180deg)`,
  //   opacity: signInForm ? 1 : 0,
  //   zIndex: signInForm ? 10 : -1,
  //   config: { mass: 5, tension: 500, friction: 80 },
  // });

  // const forgotPasswordAnime = useSpring({
  //   tranform: forgotPassword
  //   ? `perspective(600px) rotateY(0)`
  //   : signInForm
  //   ? `perspective(600px) rotateY(180deg)`
  //   : `perspective(600px) rotateX(0)`,
  //   opacity: forgotPassword ? 1 : 0,
  //   zIndex: forgotPassword ? 10 : -1,
  //   config: { mass: 5, tension: 500, friction: 80 },
  // });

  // const forgotPasswordAnime = useSpring({
  //   to: forgotPassword ? async (next,cancel) => {
  //     await next({})
  //   },
  //   config: { mass: 5, tension: 500, friction: 80 },
  // });

  const userTypeOptions = [
    { value: "Designer", label: "Designer" },
    { value: "Shopper", label: "Shopper" },
  ];

  //<SELECT /> Styling
  const selectStyles = {
    option: (base, { isFocused }) => ({
      ...base,
      borderRadius: "20px",
      color: isFocused ? "white" : "#692aa9",
      backgroundColor: isFocused ? "#8142c0" : "white",
    }),
    control: (base) => ({
      ...base,
      // width: `${8 * userType.label.length + 70}px`,
      width: `${200}px`,
      borderWidth: "2px",
      borderColor: "#8142c0",
      height: "35px",
      minHeight: "35px",
      flexWrap: "nowrap !important",
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: "600",
      fontSize: "16",
      color: "#8142c0",
    }),
  };

  //<SELECT /> Theming
  const selectTheme = (theme) => {
    return {
      ...theme,
      height: "30px",
      minHeight: "30px",
      borderRadius: "20px",
      colors: {
        ...theme.colors,
        primary: "#8142c0",
      },
    };
  };

  return (
    <>
      <div id="authbox" className="auth-popup-box">
        <animated.div className="auth-box" style={signInFormAnime}>
          <span className="auth-close-icon" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
          <b className="signin-bold">Sign In</b>
          <div className="usercircle-signin">
            <FontAwesomeIcon icon={faUserCircle} />
          </div>
          {authMessage === null ? null : (
            <span className="signin-error">{authMessage}</span>
          )}
          <form className="signInForm" onSubmit={handleSubmit2(onSubmitSignIn)}>
            <TextInput
              className="signin-input"
              type="email"
              name="email"
              label="Email"
              formRef={register2({ required: true })}
            />
            {errors2.email && (
              <span className="signin-error">This field is required</span>
            )}
            <TextInput
              className="signin-input"
              type="password"
              name="password"
              label="Password"
              formRef={register2({ required: true })}
            />
            {errors2.password && (
              <span className="signin-error">This field is required</span>
            )}
            <button className="signup" type="submit">
              {isLoading ? (
                <PropagateLoader
                  className="loader"
                  size={10}
                  color={"#70116b"}
                  loading={true}
                />
              ) : (
                <span>Sign In</span>
              )}
            </button>
          </form>
          <span
            className="forgot-password"
            onClick={() => {
              setForgotPassword(true);
              setSignUpForm(false);
              setSignInForm(false);
            }}
          >
            Forgot Password
          </span>
          <span
            className="signup-link"
            onClick={() => {
              setSignUpForm(true);
              setSignInForm(false);
              setForgotPassword(false);
            }}
          >
            Sign Up
          </span>
        </animated.div>

        <animated.div className="auth-box" style={forgotPasswordAnime}>
          <span className="auth-close-icon" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
          <b className="signin-bold">Forgot Password</b>
          <div className="usercircle-signin">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
          <span className="reset-password-desc">
            Input your email and we'll send you a one time reset password link
          </span>
          <form
            className="signInForm"
            onSubmit={handleSubmit3(onSubmitForgotPassword)}
          >
            <TextInput
              className="signin-input"
              type="email"
              name="email"
              label="Email"
              formRef={register3({ required: true })}
            />
            {errors3.email && (
              <span className="signin-error">This field is required</span>
            )}
            {forgotMessage === null ? null : (
            <span className="forgot-message-auth">{forgotMessage}</span>
          )}
            <button className="signup" type="submit">
              {isLoading ? (
                <PropagateLoader
                  className="loader"
                  size={10}
                  color={"#70116b"}
                  loading={true}
                />
              ) : (
                <span>Reset Password</span>
              )}
            </button>
          </form>
          <span
            className="signup-link"
            onClick={() => {
              setSignUpForm(false);
              setSignInForm(true);
              setForgotPassword(false);
            }}
          >
            Sign In
          </span>
        </animated.div>

        <animated.div className="auth-box" style={signUpFormAnime}>
          <span className="auth-close-icon" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
          <b className="signin-bold">Sign Up</b>
          <div className="usercircle-signin">
            <FontAwesomeIcon icon={UserCircle} />
          </div>
          <span className="signup-span">
            Input your email and we'll send you a signup form
          </span>
          <form className="signInForm" onSubmit={handleSubmit(onSubmitSignUp)}>
            <div className="select-popup-container">
              <Controller
                name="usertype"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
                render={({ name, onChange, value }) => (
                  <Select
                    name={name}
                    theme={selectTheme}
                    styles={selectStyles}
                    value={value}
                    onChange={onChange}
                    options={userTypeOptions}
                  />
                )}
              />
            </div>
            {errors.usertype && (
              <span style={{ fontSize: "0.9rem", color: "red" }}>
                Select an option
              </span>
            )}
            <TextInput
              className="signin-input"
              type="email"
              name="email"
              label="Email"
              formRef={register({ required: true })}
            />
            {errors.email && (
              <span className="signin-error">This field is required</span>
            )}
            <button className="signup" type="submit">
              {isLoading ? (
                <PropagateLoader
                  className="loader"
                  size={10}
                  color={"#70116b"}
                  loading={true}
                />
              ) : (
                <span>Sign Up</span>
              )}
            </button>
          </form>
          <span
            className="signup-link"
            onClick={() => {
              setSignUpForm(false);
              setSignInForm(true);
              setForgotPassword(false);
            }}
          >
            Sign In
          </span>
        </animated.div>
      </div>
      {PopUp && <OnSignUpPopUp handleClose={togglePopup} msg={message} />}
    </>
  );
}

export default AuthPopUp;
