import React, { useEffect, useRef, useState } from "react";
import "./menu.css";

import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import AuthPopUp from "../AuthPopUp/AuthPopUp.js";
import { useSpring, animated } from "react-spring";

function Menu({ scrolled, open, setOpen, setIsAuth, setUserId }) {
  let menuClasses = ["menu"];
  if (open) {
    menuClasses.push("menuclick");
  }

  if (!open) {
  }

  let menuItemClasses = [""];
  if (open) {
    menuItemClasses.push("openMenuItem");
  }

  let scrolledClasses = ["menuitem"];
  if (scrolled) {
    scrolledClasses.push("scrolledmenu");
  }

  let scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [PopUp, setPopUp] = useState(false);

  const togglePopup = () => {
    setPopUp(!PopUp);
  };

  const springProps = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0 },
    to: {
      opacity: PopUp ? 1 : 0,
    },
  });

  const node = useRef();

  useEffect(() => {
    //handle click outside when menu is open
    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      // outside click
      setOpen(false);
    };

    const handleResize = () => {
      if (open) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("click", handleClickOutside);
      window.addEventListener("resize", handleResize);
    } else {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect(() => {
  //   //handle click outside when auth PopUp is open
  //   const handleClickOutsidePop = (e) => {
  //     if (e.target !== document.querySelector("#authbox")) {
  //       return;
  //     }
  //     // outside click
  //     setPopUp(false);
  //   };

  //   if (PopUp) {
  //     document.addEventListener("click", handleClickOutsidePop);
  //   } else {
  //     document.removeEventListener("click", handleClickOutsidePop);
  //   }

  //   return () => {
  //     document.removeEventListener("click", handleClickOutsidePop);
  //   };
  // }, [PopUp]);

  return (
    <header className="headermenu">
      <ul ref={node} className={menuClasses.join(" ")}>
        <li
          className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
            " "
          )}`}
          onClick={scrollToTop}
        >
          Home
        </li>
        <div className="menuitem-border" />
        <Link
          activeClass="active"
          to="hiw"
          spy={true}
          smooth={true}
          offset={-90}
          duration={500}
        >
          <li
            className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
              " "
            )}`}
          >
            How It Works
          </li>
        </Link>
        <div className="menuitem-border" />
        <Link
          activeClass="active"
          to="shop"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
        >
          <li
            className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
              " "
            )}`}
          >
            Shop
          </li>
        </Link>
        <div className="menuitem-border" />
        <Link
          activeClass="active"
          to="design"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
        >
          <li
            className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
              " "
            )}`}
          >
            Design
          </li>
        </Link>
        <div className="menuitem-border" />
        <Link
          activeClass="active"
          to="prelaunch"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
        >
          <li
            className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
              " "
            )}`}
          >
            Invite
          </li>
        </Link>
        <div className="menuitem-border" />
        <Link
          activeClass="active"
          to="supplier"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
        >
          <li
            className={`${scrolledClasses.join(" ")} ${menuItemClasses.join(
              " "
            )}`}
          >
            Supplier
          </li>
        </Link>
        <div className="menuitem-border" />
        <div className="usercircle" onClick={togglePopup}>
          <FontAwesomeIcon
            icon={faUserCircle}
            color={scrolled || open ? "#FFFFFF" : "#692aa9"}
          />
        </div>
      </ul>
      {PopUp && (
        <animated.div keystyle={springProps}>
          <AuthPopUp handleClose={togglePopup} setIsAuth={setIsAuth} setUserId={setUserId}/>
        </animated.div>
      )}
    </header>
  );
}

export default Menu;
