import React from "react";
import "./textInput.css";

function TextInput({ type, name, label, formRef}) {
  return (
    <div className="textinput-field">
      <input
        type={type}
        name={name}
        className="textinput"
        placeholder=" "
        ref={formRef}
      />
      <label htmlFor={name} className="textinput-label">
        {label}
      </label>
    </div>
  );
}
export default TextInput;
