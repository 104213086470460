import React from "react";
import "./footer.css";

import LogoWhite from "../../assets/images/Logo/drapem-logo-white.png";

function Footer() {
  return (
    <header className="footer">
      <div className="footerleftcontainer">
        <div className="socialfooter">
          <h2 className="socialheader">Social</h2>
          <a
            href="https://www.facebook.com/drapemfashion/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facbook
          </a>
          <a
            href="https://www.instagram.com/drapemfashion/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </div>
        <div className="contactus">
          <h2 className="contactlink">Contact Us</h2>
          <a href="mailto:hi@drapem.com">hi@drapem.com</a>
        </div>
      </div>
      <div className="footerrightcontainer">
        <div className="footerlogo">
          <img className="logo" alt="logo" src={LogoWhite} />
        </div>
        <p className="drapemcorp">Metro Manila, Philippines</p>
      </div>
    </header>
  );
}

export default Footer;
