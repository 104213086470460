import React, { useEffect } from "react";

import "./navbar.css";
import LogoWhite from "../../assets/images/Logo/drapem-logo-white.png";
import LogoPurp from "../../assets/images/Logo/drapem-logo-purp.png";
import Burger from "../Burger/Burger.js";

function Navbar({ setIsAuth, setUserId}) {
  //HANDLE STICKY
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 400) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  //STICKY HOOK
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  let reloadPage = () => {
    window.location.reload()
  }

  return (
    <header className={navbarClasses.join(" ")}>
      <div className="logo">
        <img
          className="logo"
          alt="logo"
          src={scrolled ? LogoWhite : LogoPurp}
          onClick={reloadPage}
        />
      </div>
      <p className="comingsoon">Coming Soon!</p>
      <Burger scrolled={scrolled} setIsAuth={setIsAuth} setUserId={setUserId} />
    </header>
  );
}

export default Navbar;
