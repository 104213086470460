import React from "react";
import "./onSignUpPopUp.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";

function OnSignUpPopUp({ handleClose, msg }) {
  const animation = useSpring({
    from: { transform: "rotateY(0deg)" },
    to: async (next) => {
      while (1) {
        await next({ transform: "rotateY(360deg)" });
        await next({ transform: "rotateY(0deg)" });
        await next({ transform: "rotateY(360deg)" });
        await next({ transform: "rotateY(0deg)" });
      }
    },
    config: { tension: 20 },
  });

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <b className="popupmessage">{msg}</b>
        <animated.div style={animation} className="popup-paperplane">
          <FontAwesomeIcon icon={faPaperPlane} />
        </animated.div>
      </div>
    </div>
  );
}

export default OnSignUpPopUp;
