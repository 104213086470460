import React from "react";
import "./hiw.css";

import {Spring} from 'react-spring/renderprops';
import VisibilitySensor from 'react-visibility-sensor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons';

function Hiw() {
  return (
      <div className='hiw'>
      <div className='seg'>
      <div className='segline'/>
      <h3 className="segtitle">How It Works</h3>
      </div>
      <div className="hiwgrid">
      <div className="circle one">1</div>
      
      {/* ANIMATE ONSCROLL TO ENTER COMING FROM CONTAINER */}

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0) rotateY(0)' : 'translatex(-150%) rotateY(360deg)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <div className="palette" style={{ transform, opacity}}>
      <FontAwesomeIcon icon={faPalette} color="#ffd5cd"/>
      </div>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0)' : 'translatex(150%)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <h2 className="hiwlabel design" style={{ transform, opacity }} >Design</h2>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{opacity: isVisible ? 1 : 0}}>
      {({opacity }) => (
      <p className="desc design" style={{opacity}}>
        Designers create fashion sketches getting inspiration from our fabric
        partners
      </p>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <div className='linesep one'></div>
      <div className="circle two">2</div>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0) rotateY(0)' : 'translatex(150%) rotateY(-360deg)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <div className="cut" style={{transform, opacity}}>
      <FontAwesomeIcon icon={faCut} color="#efbbcf"/>
      </div>
      )}
      </Spring>
      )}
      </VisibilitySensor>
      
      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0)' : 'translatex(-150%)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <h2 className="hiwlabel sample" style={{transform,opacity}}>Sample</h2>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{opacity: isVisible ? 1 : 0}}>
      {({opacity }) => (
      <p className="desc sample" style={{opacity}}>
        drapem creates the clothing sample and design pattern through close
        coordination with the designer
      </p>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <div className='linesep two'></div>
      <div className="circle three">3</div>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0) rotateY(0)' : 'translatex(-150%) rotateY(360deg)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <div className="shopbag" style={{transform,opacity}}>
      <FontAwesomeIcon icon={faShoppingBag} color="#c3aed6"/>
      </div>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0)' : 'translatex(150%)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <h2 className="hiwlabel shop" style={{transform,opacity}}>Shop</h2>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{opacity: isVisible ? 1 : 0}}>
      {({opacity }) => (
      <p className="desc shop" style={{opacity}}>
        Shoppers pick from the variety of clothing styles and orders from a
        partner manufacturer
      </p>
      )}
      </Spring>
      )}
      </VisibilitySensor>


      <div className='linesep three'></div>
      <div className="circle four">4</div>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0) rotateY(0)' : 'translatex(150%) rotateY(-360deg)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <div className="ship" style={{transform,opacity}}>
      <FontAwesomeIcon icon={faShippingFast} color="#8675a9"/>
      </div>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{transform: isVisible ? 'translatex(0)' : 'translatex(-150%)', opacity: isVisible ? 1 : 0}}>
      {({ transform, opacity }) => (
      <h2 className="hiwlabel create" style={{transform,opacity}}>Create & Ship</h2>
      )}
      </Spring>
      )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (<Spring to={{opacity: isVisible ? 1 : 0}}>
      {({opacity }) => (
      <p className="desc create" style={{opacity}}>
        The chosen manufacturer will produce the clothing apparel tailored to
        the shoppers size and ships it to the customer
      </p>
      )}
      </Spring>
      )}
      </VisibilitySensor>

    </div>
    </div>
  );
}

export default Hiw;
