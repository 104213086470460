import React, {useState} from "react";
import "./supplier.css";

import { Spring } from "react-spring/renderprops";
import { useForm } from "react-hook-form";
import VisibilitySensor from "react-visibility-sensor";
import ProductionImage from "../../assets/images/Supplier/Production.jpg";
import TextInput from "../TextInput/TextInput.js";
import PropagateLoader from "react-spinners/PropagateLoader";

function Supplier() {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState()
  const [message, setMessage] = useState(null);

  const onSubmitSupplierForm = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/suppliercontact`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        firstname: data.firstName,
        lastname: data.lastName,
        email: data.email,
        mobilenumber: data.mobileNumber,
        companyname: data.companyName,
        country: data.country,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg);
      }).catch(err => {
        console.log("An error occured submitting signup")
      })
  };

  return (
    <header className="suppliersegment">
      <div className="seg">
        <div className="segline" />
        <h3 className="segtitle">Supplier/Manufacturer</h3>
      </div>
      <div className="suppliercontainer">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="productionimage" style={{ transform, opacity }}>
                  <img src={ProductionImage} alt="Clothing Prodution" />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <div className="supplierformcontainer">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <p
                    className="callforsuppliers"
                    style={{ transform, opacity }}
                  >
                    Are you a fabric/accessories supplier or manufacturer? Sell
                    on drapem!
                  </p>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <form
            className="supplierform"
            onSubmit={handleSubmit(onSubmitSupplierForm)}
          >
            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="text"
                        name="firstName"
                        label="First Name"
                        formRef={register({ required: true })}
                      />
                      {errors.firstName && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="text"
                        name="lastName"
                        label="Last Name"
                        formRef={register({ required: true })}
                      />
                      {errors.lastName && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="email"
                        name="email"
                        label="Email"
                        formRef={register({ required: true })}
                      />
                      {errors.email && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="tel"
                        name="mobileNumber"
                        // label="Mobile Number (+63 123 456 7890)"
                        label="Mobile Number"
                        formRef={register({
                          required: true,
                          // eslint-disable-next-line
                          pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                        })}
                      />
                      {errors.mobileNumber?.type === "required" && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                      {errors.mobileNumber?.type === "pattern" && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          Please enter a valid number format e.g. +63 123 456
                          7890
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="text"
                        name="companyName"
                        label="Company Name"
                        formRef={register({ required: true })}
                      />
                      {errors.companyName && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ transform, opacity }) => (
                    <div style={{ transform, opacity }}>
                      <TextInput
                        type="text"
                        name="country"
                        label="Country"
                        formRef={register({ required: true })}
                      />
                      {errors.country && (
                        <span style={{ fontSize: "0.9rem", color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            {message === null ? null : (
            <span className="signin-error">{message}</span>
          )}

            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ opacity }) => (
                    <button
                      type="submit"
                      className="getintouch"
                      style={{ opacity }}
                    >
                      {isLoading ? (
                        <PropagateLoader
                          className="loader"
                          size={10}
                          color={"#70116b"}
                          loading={true}
                        />
                      ) : (
                        <span>Sign Up</span>
                      )}
                    </button>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          </form>
        </div>
      </div>
    </header>
  );
}

export default Supplier;
