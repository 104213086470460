import React, { useState } from "react";
import "./preLaunch.css";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import TextInput from "../TextInput/TextInput.js";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faAtom } from "@fortawesome/free-solid-svg-icons";
import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import OnSignUpPopUp from "../OnSignUpPopUp/OnSignUpPopUp.js";
import PropagateLoader from "react-spinners/PropagateLoader";

function PreLaunch() {
  const { register, handleSubmit, control, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmitSignUp = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/signup`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        email: data.email,
        usertype: data.usertype.value,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg);
        togglePopup();
      });
  };

  const [PopUp, setPopUp] = useState(false);

  const togglePopup = () => {
    setPopUp(!PopUp);
  };

  const userTypeOptions = [
    { value: "Designer", label: "Designer" },
    { value: "Shopper", label: "Shopper" },
  ];

  //<SELECT /> Styling
  const selectStyles = {
    option: (base, { isFocused }) => ({
      ...base,
      borderRadius: "20px",
      color: isFocused ? "white" : "#692aa9",
      backgroundColor: isFocused ? "#8142c0" : "white",
    }),
    control: (base) => ({
      ...base,
      // width: `${8 * userType.label.length + 70}px`,
      width: `${134}px`,
      borderWidth: "2px",
      borderColor: "#8142c0",
      height: "35px",
      minHeight: "35px",
      flexWrap: "nowrap !important",
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: "600",
      fontSize: "16",
      color: "#8142c0",
    }),
  };

  //<SELECT /> Theming
  const selectTheme = (theme) => {
    return {
      ...theme,
      height: "30px",
      minHeight: "30px",
      borderRadius: "20px",
      colors: {
        ...theme.colors,
        primary: "#8142c0",
      },
    };
  };

  return (
    <header className="prelaunchsegment">
      <div className="seg">
        <div className="segline" />
        <h3 className="segtitle">Pre-Launch Exclusives</h3>
      </div>
      <div className="prelaunchcontainer">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="referralcta" style={{ transform, opacity }}>
                  <div className="purpdash"></div>
                  <h1 className="referralmaintext">Sign up and invite!</h1>
                  <h2 className="referralsuptext">
                    We know you can't wait, so we're giving you something to
                    look forward to. A referral link will be sent to you once
                    you sign up!
                  </h2>
                  <form className="cta" onSubmit={handleSubmit(onSubmitSignUp)}>
                    <div className="useremail">
                      <div className="usertype">
                        <Controller
                          name="usertype"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          }}
                          render={({ name, onChange, value }) => (
                            <Select
                              name={name}
                              theme={selectTheme}
                              styles={selectStyles}
                              value={value}
                              onChange={onChange}
                              options={userTypeOptions}
                            />
                          )}
                        />
                        {errors.usertype && (
                          <span style={{ fontSize: "0.9rem", color: "red" }}>
                            Select an option
                          </span>
                        )}
                      </div>
                      <div className="emailinput">
                        <TextInput
                          type="email"
                          name="email"
                          label="Email"
                          formRef={register({ required: true })}
                        />
                        {errors.email && (
                          <span style={{ fontSize: "0.9rem", color: "red" }}>
                            The email field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <button type="submit" className="signup">
                      {isLoading ? (
                        <PropagateLoader
                          className="loader"
                          size={10}
                          color={"#70116b"}
                          loading={true}
                        />
                      ) : (
                        <span>Sign Up</span>
                      )}
                    </button>
                  </form>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        {PopUp && <OnSignUpPopUp handleClose={togglePopup} msg={message} />}

        <div className="referraltiers">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="bullhorn" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faBullhorn} color="#4A47A3" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tieronetext" style={{ transform, opacity }}>
                    <h4 className="tieroneheader">Sign Up</h4>
                    <p className="tieronedesc">
                      Be the First to Hear When We Launch!
                    </p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <h2
                    className="tiertwousername"
                    style={{ transform, opacity }}
                  >
                    @username
                  </h2>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tiertwotext" style={{ transform, opacity }}>
                    <h4 className="tiertwoheader">2 Invites</h4>
                    <p className="tiertwodesc">Reserve Your Username</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="faatom" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faAtom} color="#4A47A3" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tierthreetext" style={{ transform, opacity }}>
                    <h4 className="tierthreeheader">3 Invites</h4>
                    <p className="tierthreedesc">Exlusive Beta Access</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="faruler" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faRuler} color="#4A47A3" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tierfourtext" style={{ transform, opacity }}>
                    <h4 className="tierfourheader">10 Invites</h4>
                    <p className="tierfourdesc">Custom drapem Measuring Tape</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="fapercent" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faPercent} color="#4A47A3" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tierfivetext" style={{ transform, opacity }}>
                    <h4 className="tierfiveheader">20 Invites</h4>
                    <p className="tierfivedesc">20% Discount Voucher</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="fatags" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faTags} color="#4A47A3" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tiersixtext" style={{ transform, opacity }}>
                    <h4 className="tiersixheader">50 Invites</h4>
                    <p className="tiersixdesc">50% Discount Voucher</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "rotateY(0)" : "rotateY(180deg)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="fagift" style={{ transform, opacity }}>
                    <FontAwesomeIcon icon={faGift} />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-50%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <div className="tierseventext" style={{ transform, opacity }}>
                    <h4 className="tiersevenheader">100 Invites</h4>
                    <p className="tiersevendesc">Free Item of Your Choice</p>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
      </div>
    </header>
  );
}

export default PreLaunch;
