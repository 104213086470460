import React from "react";
import "./design.css";

import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import techpack from "../../assets/images/Design/techpack.png";
import textileSelect from "../../assets/images/Design/textileselect.png";
import designstatus from "../../assets/images/Design/designstatus.png";
import scalability from "../../assets/images/Design/scalability.png";
import rocket from "../../assets/images/Design/rocket.png";

function Design() {
  return (
    <div className="designsegment">
      <div className="seg">
        <div className="segline" />
        <h3 className="segtitle">An Avenue For Design</h3>
      </div>

      <div className="market-designs">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <img
                  className="rocket-ship"
                  style={{ transform, opacity }}
                  src={rocket}
                  alt="Rocket Launch your Design"
                />
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(-20%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
        <div className="market-designs-text-container" style={{transform, opacity}}>
          <h2 className="market-designs-heading">
            Easily Bring Your Designs to Market
          </h2>
          <p className="market-designs-text">
            Rocket launch a clothing collection with minimal upfront cost by not
            needing to mass manufacture! Just focus on designing while drapem
            handles the rest, from sourcing materials to tailoring to delivering
            to your customers.
          </p>
        </div>
        )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>

      {/* Design Dashboard Samples */}
      <div className="techpack">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="techpacktext" style={{ transform, opacity }}>
                  <h2 className="techpackheading">From Design to Production</h2>
                  <p className="techpackdesc">
                    Clearly relay your vision through easy-to-use tech packs
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <div className="techpackimage" style={{ opacity }}>
                  <img
                    className="techpackimage"
                    src={techpack}
                    alt="mock techpack"
                  />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>

      <div className="designdashboard">
        <div className="fabricselection">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                {({ opacity }) => (
                  <div className="textileselectimage" style={{ opacity }}>
                    <img src={textileSelect} alt="Textile Selection" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-70%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <p className="textiletext" style={{ transform, opacity }}>
                    Enhance your styles through a range of fabric and accessory
                    selections
                  </p>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>

        <div className="designstatus">
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                {({ opacity }) => (
                  <div className="statusimage" style={{ opacity }}>
                    <img src={designstatus} alt="Design Status Dashboard" />
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <Spring
                to={{
                  transform: isVisible ? "translatey(0)" : "translatey(-70%)",
                  opacity: isVisible ? 1 : 0,
                }}
              >
                {({ transform, opacity }) => (
                  <p className="statustext" style={{ transform, opacity }}>
                    Keep track of the production process for all your designs
                  </p>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
      </div>

      {/* Scalability Section */}

      <div className="scalability">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              to={{
                transform: isVisible ? "translatex(0)" : "translatex(10%)",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {({ transform, opacity }) => (
                <div className="scalabilitytext" style={{ transform, opacity }}>
                  <h2 className="scalabilityheading">
                    From Production to Scalability
                  </h2>
                  <p className="scalabilitydesc">
                    drapem crafts your design to reusable samples and patterns
                    that allow multiple manufacturers to easily produce to your
                    customer
                  </p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => (
            <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) => (
                <div className="scalabilityimage" style={{ opacity }}>
                  <img src={scalability} alt="mock techpack" />
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default Design;
