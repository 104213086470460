import React, { useEffect } from "react";

import "./home.css";
import axios from "axios";
import { useParams } from "react-router-dom";

import Navbar from "../../components/Header/Navbar.js";
import Hero from "../../components/Hero/Hero.js";
import Hiw from "../../components/Hiw/Hiw.js";
import Shop from "../../components/Shop/Shop.js";
import Design from "../../components/Design/Design.js";
import PreLaunch from "../../components/PreLaunch/PreLaunch.js";
import Supplier from "../../components/Supplier/Supplier.js";
import Footer from "../../components/Footer/Footer.js";
import Helmet from "../../components/helmet";

function Home({ setIsAuth, setUserId }) {
  let { Token } = useParams();
  useEffect(() => {
    async function getCookies() {
      await axios.get(`${process.env.REACT_APP_SERVER}/invite/${Token}`, {
        withCredentials: true,
      });
    }

    getCookies();
  }, [Token]);

  return (
    <>
      <Helmet />
      <div className="home-page">
        <div className="herosegment">
          <Navbar setIsAuth={setIsAuth} setUserId={setUserId} />
          <Hero />
        </div>
        <div className="hiwsegment" id="hiw">
          <Hiw />
        </div>
        <div className="shopsegment" id="shop">
          <Shop />
        </div>
        <div className="designsegment" id="design">
          <Design />
        </div>
        <div className="prelaunchsegment" id="prelaunch">
          <PreLaunch />
        </div>
        <div className="suppliersegment" id="supplier">
          <Supplier />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
