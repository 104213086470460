import React, { useRef, useEffect, useState } from "react";
import "./Register.css";

import whiteHook from "../../assets/images/Logo/white-hook.png";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../components/TextInput/TextInput.js";
import RegisterPopUp from "../../components/registerPopUp/registerPopUp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Link } from "react-router-dom";
import Helmet from "../../components/helmet";
import Select from "react-select";
import countryList from "react-select-country-list";
import validator from "validator";

function Register() {
  let { signUpToken } = useParams();

  const [email, setEmail] = useState("No Email Found");
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    setCountries(countryList().getData());
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER}/getemail`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: signUpToken,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setEmail(data);
      })
      .catch((err) => setEmail("No Email Found"));
  }, [signUpToken]);

  const { register, handleSubmit, errors, watch, control } = useForm();
  const password = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const selectStyles = {
    option: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? "white" : "#692aa9",
      backgroundColor: isFocused ? "#8142c0" : "white",
    }),
    control: (base) => ({
      ...base,
      // width: `${8 * userType.label.length + 70}px`,
      width: `${200}px`,
      borderWidth: "2px",
      borderColor: "#8142c0",
      height: "35px",
      minHeight: "35px",
      flexWrap: "nowrap !important",
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: "600",
      fontSize: "16",
      color: "#8142c0",
    }),
  };

  //<SELECT /> Theming
  const selectTheme = (theme) => {
    return {
      ...theme,
      height: "30px",
      minHeight: "30px",
      colors: {
        ...theme.colors,
        primary: "#8142c0",
      },
    };
  };

  password.current = watch("password", "");

  const onSubmitRegister = (data) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/register`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        firstname: data.firstname,
        lastname: data.lastname,
        country: data.country.label,
        mobilenumber: data.mobilenumber,
        password: data.password,
        token: signUpToken,
      }),
    })
      .then((response) => response.json())
      .then((msg) => {
        setIsLoading(false);
        setMessage(msg);
        togglePopup();
      });
  };

  const [PopUp, setPopUp] = useState(false);

  const togglePopup = () => {
    setPopUp(!PopUp);
  };

  return (
    <>
      <Helmet title="drapem | Register" />
      <div className="register-bg">
        <div className="large-hook">
          <img alt="drapem Hook" src={whiteHook} />
        </div>
        <div className="form-container">
          <div className="register-form-container">
            <div className="home-link-container">
              <Link className="home-link" to="/">
                Go Back
              </Link>
            </div>
            <h2 className="register-heading">Register</h2>
            <div className="email-container">
              <div className="email-icon-register">
                <FontAwesomeIcon icon={faEnvelopeSquare} />
              </div>
              <h2 className="registering-email">{email}</h2>
            </div>
            <form
              className="register-form"
              onSubmit={handleSubmit(onSubmitRegister)}
            >
              <TextInput
                type="text"
                name="firstname"
                label="First Name"
                formRef={register({
                  required: "The first name field is required",
                })}
              />
              {errors.firstname && (
                <span className="span-error-register">
                  {errors.firstname.message}
                </span>
              )}
              <TextInput
                type="text"
                name="lastname"
                label="Last Name"
                formRef={register({
                  required: "The last name field is required",
                })}
              />
              {errors.lastname && (
                <span className="span-error-register">
                  {errors.lastname.message}
                </span>
              )}
              <div className="country-selector">
                <span className="country-input">Country: </span>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ name, onChange, value }) => (
                    <Select
                      name={name}
                      options={countries}
                      value={value}
                      onChange={onChange}
                      theme={selectTheme}
                      styles={selectStyles}
                    />
                  )}
                />
              </div>
              {errors.country && (
                <span className="span-error-register">Select an option</span>
              )}
              <TextInput
                type="text"
                name="mobilenumber"
                label="Mobile Number"
                formRef={register({
                  validate: (value) => validator.isMobilePhone(value),
                })}
              />
              {errors.mobilenumber && (
                <span className="span-error-register">
                  Please enter a valid mobile number
                </span>
              )}
              <TextInput
                type="password"
                name="password"
                label="Password"
                formRef={register({
                  required: "The password field is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />
              {errors.password && (
                <span className="span-error-register">
                  {errors.password.message}
                </span>
              )}
              <TextInput
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                formRef={register({
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {errors.confirmPassword && (
                <span className="span-error-register">
                  {errors.confirmPassword.message}
                </span>
              )}
              <button className="register-button" type="submit">
                {isLoading ? (
                  <PropagateLoader
                    className="loader"
                    size={10}
                    color={"#70116b"}
                    loading={true}
                  />
                ) : (
                  <span>Sign Up</span>
                )}
              </button>
            </form>
          </div>
        </div>
        {PopUp && <RegisterPopUp handleClose={togglePopup} msg={message} />}
      </div>
    </>
  );
}

export default Register;
